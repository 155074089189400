require('normalize.css/normalize.css');
require('./styles/index.scss');
require('./lazy.js');

/**
 * ANIMATE ON SCROLL
 */
// get the element to animate
var element = document.querySelectorAll('.animate-slide-in');


var animeElements = document.querySelectorAll('.animate-slide-in'), i;
var animeElementsSize = animeElements.length;

var main = document.querySelector('main');


// listen for scroll and document ready event and call animate function
main.addEventListener('scroll', animate); //scroll
(function () { //ready
    animate();
})();
// check if element is in view
function inView(element) {
    // get window height
    var windowHeight = window.innerHeight;
    // get number of pixels that the document is scrolled
    var scrollY = window.scrollY || window.pageYOffset;
    // get element height
    var elementHeight = element.clientHeight;

    // get current scroll position (distance from the top of the page to the bottom of the current viewport)
    var scrollPosition = scrollY + windowHeight + elementHeight;
    // get element position (distance from the top of the page to the bottom of the element)
    var elementPosition = element.getBoundingClientRect().top + scrollY + elementHeight;

    // is scroll position greater than element position? (is element in view?)
    if (scrollPosition > (elementPosition + 40)) {
        return true;
    }

    return false;
}


function animate() {
    for (i = 0; i < animeElementsSize; ++i) {
        var element = animeElements[i];
        if (inView(element)) {
            element.classList.add('animate-go');
        }
        else {
            element.classList.remove('animate-go');
        }
    }
}



/*
 * CONTACT FORM
 */
var form = document.getElementById("contact-form");
function submitHandler(submitEvent) {
    if (form.checkValidity()) {
        submitEvent.preventDefault();
    } 
}
form.addEventListener("submit", submitHandler, false);

function submitContactForm() {
    var sharedTimeOut;
    var elements = document.getElementsByClassName("form-el");
    var submitButton = document.getElementById("submit-it");
    if (!form.checkValidity()) {
        return;
    }
    var formData = new FormData();
    for (var i = 0; i < elements.length; i++) {
        if (elements[i].name == "cgu") {
            formData.append(elements[i].name, elements[i].checked);
        }
        else {
            formData.append(elements[i].name, elements[i].value);
        }
        
    }
    var xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = function() {
        if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
            var response = xmlHttp.responseText;
            if (response == "ok") {
                clearTimeout(sharedTimeOut);
                submitButton.setAttribute("disabled", "disabled");
                submitButton.classList.remove("error");
                submitButton.classList.add("success");
                submitButton.innerHTML = "Message envoyé avec succès !";
                sharedTimeOut = setTimeout(function() {
                    submitButton.removeAttribute("disabled");
                    submitButton.classList.remove("success");
                    form.reset();
                    submitButton.innerHTML = "ENVOYER";
                }, 3000);
            } else {
                clearTimeout(sharedTimeOut);
                submitButton.setAttribute("disabled", "disabled");
                submitButton.classList.remove("success");
                submitButton.classList.add("error");
                submitButton.innerHTML = "Errors lors de l'envoi. Veuillez recommencer.";
                sharedTimeOut = setTimeout(function() {
                    submitButton.removeAttribute("disabled");
                    submitButton.classList.remove("error");
                    submitButton.innerHTML = "ENVOYER";
                }, 3000);
                console.log("mail: ", xmlHttp.responseText);
            }
        }
    };
    xmlHttp.open("post", "handle_form_submit.php");
    xmlHttp.send(formData);
}
window.submitContactForm = submitContactForm;

/* 
 * AUTO-SCROLL 
 */
function scrollTo(to, duration) {
    var element = document.querySelector('main');

    if (duration <= 0) return;
    var difference = to - element.scrollTop;
    var perTick = difference / duration * 10;

    setTimeout(function() {
        element.scrollTop = element.scrollTop + perTick;
        if (element.scrollTop === to) return;
        scrollTo(to, duration - 10);
    }, 10);
}
window.scrollTo = scrollTo;



/* LAZY LOADING */
const images = document.querySelectorAll('img[loading="lazy"]');
images.forEach(img => {
    img.src = img.dataset.src;
});

console.log('loaded');